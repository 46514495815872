import { FC } from 'react';
import { Button, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';

import styles from './NotFound.module.scss';
import { RoutersEnum } from 'routes';

const NotFound: FC = () => {
    const navigate = useNavigate();
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const isAuthenticated = Boolean(accessToken && refreshToken);
    const handleGoBack = () => {
        if (isAuthenticated) {
            navigate(RoutersEnum.sets);
        } else {
            navigate(RoutersEnum.login);
        }
    };

    return (
        <Row className={styles['wrapper']}>
            <Col className={styles['title']}>{isAuthenticated ? '404' : '401'}</Col>
            <Col className={styles['description']}>
                {isAuthenticated
                    ? `The Page you're looking for was not found.`
                    : 'You do not have authorization.'}
            </Col>
            <Button className={styles['btn-back']} onClick={handleGoBack}>
                {isAuthenticated ? 'Go to Home' : 'Return to login page'}
            </Button>
        </Row>
    );
};

export default NotFound;
