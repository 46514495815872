export enum MAIN_MENU_LINKS {
    HOME = 'home',
    USERS = 'users',
    CUSTOMERS = 'customers',
    SETS = 'sets',
    TRANSLATIONS = 'translations',
    CATEGORIES = 'categories',
}

export enum PROFILE_MENU_LINKS {
    PROFILE = 'profile',
    LOGOUT = 'logout',
}
