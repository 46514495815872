// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__Ufqol {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color--gray-5);
  padding: 6px 30px;
  max-height: 52px;
  width: 100%;
  z-index: 10;
}
.Header_header__Ufqol > .Header_main-menu__L4KiV {
  width: 120px;
  background-color: transparent;
  border-bottom: none;
}
@media (min-width: 700px) {
  .Header_header__Ufqol > .Header_main-menu__L4KiV {
    width: 550px;
  }
}

.Header_user-menu-title__3AGEc {
  cursor: default !important;
  color: black !important;
  word-wrap: break-word;
  max-width: 260px;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/header/Header.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,sCAAA;EACA,iBAAA;EACA,gBAAA;EACA,WAAA;EACA,WAAA;AACJ;AACI;EACI,YAAA;EAMA,6BAAA;EACA,mBAAA;AAJR;AADQ;EAHJ;IAIQ,YAAA;EAIV;AACF;;AAGA;EACI,0BAAA;EACA,uBAAA;EACA,qBAAA;EACA,gBAAA;AAAJ","sourcesContent":[".header {\n    position: fixed;\n    top:0;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    background-color: var(--color--gray-5);\n    padding: 6px 30px;\n    max-height: 52px;\n    width: 100%;\n    z-index: 10;\n\n    & > .main-menu {\n        width: 120px;\n\n        @media (min-width: 700px) {\n            width: 550px;\n        }\n\n        background-color: transparent;\n        border-bottom: none;\n    }\n}\n\n.user-menu-title {\n    cursor: default !important;\n    color:black !important;\n    word-wrap: break-word;\n    max-width: 260px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__Ufqol`,
	"main-menu": `Header_main-menu__L4KiV`,
	"user-menu-title": `Header_user-menu-title__3AGEc`
};
export default ___CSS_LOADER_EXPORT___;
