import { RolesEnum } from 'shared/types/roles';
import React from 'react';

const NotFound = React.lazy(() => import('pages/not-found'));
const Login = React.lazy(() => import('pages/login'));
const Customers = React.lazy(() => import('pages/customers'));
const Users = React.lazy(() => import('pages/users'));
const Sets = React.lazy(() => import('pages/sets'));
const Set = React.lazy(() => import('pages/set'));
const Home = React.lazy(() => import('pages/home'));
const Translations = React.lazy(() => import('pages/translations'));
const TranslationsGroup = React.lazy(() => import('pages/translations-group'));
const UserProfile = React.lazy(() => import('pages/user-profile'));
const CustomerProfile = React.lazy(() => import('pages/customer-profile'));
const Categories = React.lazy(() => import('pages/categories'));
const Category = React.lazy(() => import('pages/category'));

export type TRoutes = {
    name: string;
    path: string;
    Element: any;
    isAuth?: boolean;
    children?: TRoutes[];
    index?: boolean;
    permission?: string[];
};

export enum RoutersEnum {
    notFound = '/not-found',
    login = '/login',
    me = './me',
    home = '/home',
    users = '/users',
    user = '/users/id',
    customers = '/customers',
    customer = '/customers/:id',
    sets = '/sets',
    set = '/sets/:id',
    translations = '/translations',
    transaltion = '/translations/:sourceLanguage-:targetLanguage',
    categories = '/categories',
    category = '/categories/:id',
    profile = '/profile',
}

const routes = [
    {
        name: 'not-found',
        path: '/not-found',
        Element: NotFound,
        isAuth: false,
        permission: [RolesEnum.admin, RolesEnum.editor, RolesEnum.manager],
    },
    {
        name: 'login',
        path: '/login',
        Element: Login,
        isAuth: false,
    },
    {
        name: 'users',
        path: '/users',
        Element: Users,
        isAuth: true,
        permission: [RolesEnum.admin, RolesEnum.manager],
    },
    {
        name: 'customers',
        path: '/customers',
        Element: Customers,
        isAuth: true,
        permission: [RolesEnum.admin],
    },
    {
        name: 'sets',
        path: '/sets',
        Element: Sets,
        isAuth: true,
        permission: [RolesEnum.admin, RolesEnum.editor, RolesEnum.manager],
    },
    {
        name: 'set',
        path: '/sets/:id',
        Element: Set,
        isAuth: true,
        permission: [RolesEnum.admin, RolesEnum.editor, RolesEnum.manager],
    },
    {
        name: 'home',
        path: '/home',
        Element: Home,
        isAuth: true,
        permission: ['ROLE_ADMIN'],
    },
    {
        name: 'customer-profile',
        path: '/customers/:id',
        Element: CustomerProfile,
        isAuth: true,
        permission: [RolesEnum.admin],
    },
    {
        name: 'user-profile',
        path: '/users/:id',
        Element: UserProfile,
        isAuth: true,
        permission: [RolesEnum.admin, RolesEnum.manager],
    },
    {
        name: 'user-settings',
        path: '/profile',
        Element: UserProfile,
        isAuth: true,
        permission: [RolesEnum.admin, RolesEnum.editor, RolesEnum.manager],
    },
    {
        name: 'translations',
        path: '/translations',
        Element: Translations,
        isAuth: true,
        permission: [RolesEnum.admin, RolesEnum.editor, RolesEnum.manager],
    },
    {
        name: 'translations-group',
        path: '/translations/:languages',
        Element: TranslationsGroup,
        isAuth: true,
        permission: [RolesEnum.admin, RolesEnum.editor, RolesEnum.manager],
    },
    {
        name: 'categories',
        path: 'categories',
        Element: Categories,
        isAuth: true,
        permission: [RolesEnum.admin, RolesEnum.editor, RolesEnum.manager],
    },
    {
        name: 'category',
        path: '/categories/:id',
        Element: Category,
        isAuth: true,
        permission: [RolesEnum.admin, RolesEnum.editor, RolesEnum.manager],
    },
];

const routesMap = {};

routes.forEach((route) => {
    if (Object.prototype.hasOwnProperty.call(routesMap, 'name')) {
        routesMap[route.name] = route.path;
    }
});

function urlBuilder(name, params) {
    if (!Object.prototype.hasOwnProperty.call(routesMap, name)) {
        return null;
    }

    let url = routesMap[name]; // news/:id

    for (const key in params) {
        url = url.replace(`:${key}`, params[key]);
    }

    return url;
}

export default routes;
export { routesMap, urlBuilder };
