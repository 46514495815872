import { API_PREFIX, axios } from 'shared/axios';
import { TPagination } from '../types/pagination';

export type TUers = {
    id: string;
    firstName: string;
    lastName: string;
};

export type TUser = {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    roleType: string;
    createdAt: string;
    active: boolean;
};

export type TCreateUser = {
    email: string;
    firstName: string;
    lastName: string;
    role: string;
};

export type TUsersParams = TPagination & {
    searchValue: string;
};

export const getMe = async (): Promise<TUser> => {
    const { data } = await axios.get(`${API_PREFIX}/users/me`);
    return data;
};

export const getUsrsList = async (): Promise<TUers[]> => {
    const { data } = await axios.get(`${API_PREFIX}/users/list`);
    return data;
};

export const getUsers = async (params): Promise<{ content: TUser[]; totalElements: number }> => {
    const { data } = await axios.get(`${API_PREFIX}/users`, { params });
    return data;
};

export const getReviewers = async (params): Promise<TUers[]> => {
    const { data } = await axios.get(`${API_PREFIX}/users/list`, { params });
    return data;
};

export const getUserProfileById = async (id: string): Promise<TUser> => {
    const { data } = await axios.get(`${API_PREFIX}/users/${id}`);
    return data;
};

export const editUser = async (id: string, params: TCreateUser): Promise<void> => {
    const { data } = await axios.put(`${API_PREFIX}/users/${id}`, { ...params });
    return data;
};

export const addUser = async (params: TCreateUser): Promise<void> => {
    const { data } = await axios.post(`${API_PREFIX}/users`, { ...params });
    return data;
};

export const activateUser = async (id: string): Promise<void> => {
    const { data } = await axios.put(`${API_PREFIX}/users/${id}/activate`);
    return data;
};
export const deactivateUser = async (id: string): Promise<void> => {
    const { data } = await axios.put(`${API_PREFIX}/users/${id}/deactivate`);
    return data;
};

export const deleteUser = async (id: string): Promise<void> => {
    const { data } = await axios.delete(`${API_PREFIX}/users/${id}`);
    return data;
};
