import { Suspense } from 'react';
import {
    Navigate,
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom';
import { Root } from './Root';
import Layout from 'shared/components/layout';
import NotFound from 'pages/not-found';
import routes, { RoutersEnum } from 'routes';
import Login from 'pages/login';
import Spiner from 'shared/components/spiner';

const generatedRoutes = (
    <>
        <Route path="/" element={<Root />} errorElement={<NotFound />}>
            {routes.map((routeItem) => {
                if (routeItem.isAuth) {
                    return (
                        <Route
                            key={routeItem.name}
                            path={routeItem.path}
                            element={
                                <Layout>
                                    <routeItem.Element />
                                </Layout>
                            }
                            errorElement={<NotFound />}
                        />
                    );
                }
                return null;
            })}
        </Route>
        <Route path={RoutersEnum.login} element={<Login />} errorElement={<NotFound />} />
        <Route path={RoutersEnum.notFound} element={<NotFound />} errorElement={<NotFound />} />
        <Route
            path="*"
            element={<Navigate replace to="/not-found" />}
            errorElement={<NotFound />}
        />
    </>
);

const router = createBrowserRouter(createRoutesFromElements(generatedRoutes));

const App = () => {
    return (
        <Suspense fallback={<Spiner />}>
            <RouterProvider router={router} />
        </Suspense>
    );
};

export default App;
