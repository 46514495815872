import Axios from 'axios';
import { getNewAccessToken } from '../services/auth.service';

export const API_HOST = process.env.REACT_APP_API_BASE_URL;

export const API_PREFIX = '/api/v1';
export const multipartFormDataConfig = {
    headers: {
        'content-type': 'multipart/form-data',
    },
};

export const axios = Axios.create({
    baseURL: `${API_HOST}`,
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
    },
});
const removeTokens = (): void => {
    window.location.pathname !== '/login' && window.location.assign('/login');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
};

axios.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');

        if (refreshToken && config.url === '/access/refresh') {
            config.headers['Authorization'] = `Bearer ${refreshToken}`;
        }

        if (accessToken && config.url !== '/access/refresh') {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }

        if (!accessToken && config.url !== '/auth/login/google') {
            return null;
        }

        return config;
    },
    (error) => Promise.reject(error),
);

axios.interceptors.response.use(
    (config) => {
        return config;
    },
    async (error) => {
        const originalRequest = error.config;

        if (originalRequest?.url === '/access/refresh') {
            localStorage.clear();
            window.location.href = '/login';
            throw error;
        }

        if (error.response?.status === 401 && error.config && !error.config._isRetry) {
            originalRequest._isRetry = true;
            try {
                const response = await getNewAccessToken();
                localStorage.setItem('accessToken', response.accessToken);
                localStorage.setItem('refreshToken', response.refreshToken);
                return axios.request(originalRequest);
            } catch (e) {
                console.log(e);
            }
        }

        if (error?.response?.data?.responseStatus === 'ACCOUNT_SUSPENDED_EXCEPTION') {
            removeTokens();
            console.log(error?.response?.data?.message);
        }

        if (error.response?.data?.responseStatus === 'INVALID_CREDENTIALS_EXCEPTION') {
            removeTokens();
            console.log(error?.response?.data?.message);
        }
        throw error;
    },
);
