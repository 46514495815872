// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFound_wrapper__TVK1G {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.NotFound_wrapper__TVK1G > .NotFound_title__lxlYZ {
  font-size: 70px;
}
.NotFound_wrapper__TVK1G > .NotFound_description__ZK\\+1o {
  font-size: 24px;
  margin-bottom: 24px;
}
.NotFound_wrapper__TVK1G > .NotFound_btn-back__e3X5l {
  height: 50px;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 24px;
  border-radius: 6px;
}`, "",{"version":3,"sources":["webpack://./src/pages/not-found/NotFound.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AACI;EACI,eAAA;AACR;AAEI;EACI,eAAA;EACA,mBAAA;AAAR;AAGI;EACI,YAAA;EACA,uBAAA;EAAA,kBAAA;EACA,eAAA;EACA,kBAAA;AADR","sourcesContent":[".wrapper {\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n\n    & > .title {\n        font-size: 70px;\n    }\n\n    & > .description {\n        font-size: 24px;\n        margin-bottom: 24px;\n    }\n\n    & > .btn-back {\n        height: 50px;\n        width:fit-content;\n        font-size: 24px;\n        border-radius: 6px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `NotFound_wrapper__TVK1G`,
	"title": `NotFound_title__lxlYZ`,
	"description": `NotFound_description__ZK+1o`,
	"btn-back": `NotFound_btn-back__e3X5l`
};
export default ___CSS_LOADER_EXPORT___;
