import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { RoutersEnum } from 'routes';
import { useQuery } from '@tanstack/react-query';
import { getMe } from 'shared/services/users.service';
import { initialization } from 'shared/services/initialization.service';
import Spiner from 'shared/components/spiner/Spiner';
import { RolesEnum } from 'shared/types/roles';

export const Root = () => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const isAuthenticated = Boolean(accessToken && refreshToken);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { data: me, isFetching: meFetching } = useQuery(['me'], () => getMe(), {
        enabled: isAuthenticated && pathname !== RoutersEnum.login,
    });

    const { isFetching: initializationFetching } = useQuery(
        ['initialization'],
        () => initialization(),
        {
            enabled: isAuthenticated && pathname !== RoutersEnum.login,
        },
    );

    const loading = meFetching || initializationFetching;

    useEffect(() => {
        if (!loading) {
            if (!isAuthenticated) {
                navigate(RoutersEnum.login);
            } else if (pathname === '/') {
                switch (me.roleType) {
                    case RolesEnum.admin:
                        navigate('/home');
                        break;
                    case RolesEnum.manager:
                        navigate('/users');
                        break;

                    default:
                        navigate('/categories');
                }
            }
        }
    }, [loading, isAuthenticated, navigate, pathname, me]);

    return loading ? <Spiner /> : <Outlet />;
};
