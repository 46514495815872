import { FC, ReactNode } from 'react';

import { Layout } from 'antd';
import Header from '../header/Header';

import styles from './Layout.module.scss';

type TPrpors = {
    children: ReactNode;
};

const MainLayout: FC<TPrpors> = (props) => {
    const { children } = props;

    return (
        <Layout className={styles.layout}>
            <Header />
            <div className={styles.container}>{children}</div>
        </Layout>
    );
};

export default MainLayout;
