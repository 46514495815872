import { FC, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { UserOutlined } from '@ant-design/icons';
import { Dropdown, Menu, MenuProps, Row, message } from 'antd';

import { RoutersEnum } from 'routes';
import { MAIN_MENU_LINKS, PROFILE_MENU_LINKS } from './constants';

import { ReactComponent as Logo } from 'shared/assets/icons/common/logo-backyard.svg';

import styles from './Header.module.scss';

import { RolesEnum } from 'shared/types/roles';
import { logOut } from 'shared/services/auth.service';

const Header: FC = () => {
    const { pathname } = useLocation();
    const queryClient = useQueryClient();
    const me: any = queryClient.getQueryData(['me']);

    const [current, setCurrent] = useState<string>('');

    const fullName = me && me?.firstName + ' ' + me?.lastName;

    useEffect(() => {
        Object.values(MAIN_MENU_LINKS).forEach((link) => {
            if (pathname.includes(link)) {
                setCurrent(link);
            }
        });
    }, [pathname]);

    const handleLogOut = async () => {
        try {
            await logOut();
            localStorage.clear();
            queryClient.clear();
            queryClient.removeQueries();
        } catch (e) {
            message.error(e?.response?.data?.message || e.message || 'Error !');
        }
    };

    const items: MenuProps['items'] = [
        {
            label: <span>{fullName}</span>,
            key: 'name',
            disabled: true,
            className: styles['user-menu-title'],
        },
        {
            type: 'divider',
        },
        {
            label: <NavLink to={`${RoutersEnum.profile}`}>Profile</NavLink>,
            key: PROFILE_MENU_LINKS.PROFILE,
        },
        {
            type: 'divider',
        },
        {
            label: <NavLink to={RoutersEnum.login}>Log out</NavLink>,
            key: PROFILE_MENU_LINKS.LOGOUT,
            onClick: () => handleLogOut(),
        },
    ];

    const menuItems = [
        {
            label: <NavLink to={RoutersEnum.home}>Home</NavLink>,
            key: MAIN_MENU_LINKS.HOME,
            access: [RolesEnum.admin],
        },
        {
            label: <NavLink to={RoutersEnum.users}>Users</NavLink>,
            key: MAIN_MENU_LINKS.USERS,
            access: [RolesEnum.admin, RolesEnum.manager],
        },
        {
            label: <NavLink to={RoutersEnum.customers}>Customers</NavLink>,
            key: MAIN_MENU_LINKS.CUSTOMERS,
            access: [RolesEnum.admin],
        },
        {
            label: <NavLink to={RoutersEnum.categories}>Categories</NavLink>,
            key: MAIN_MENU_LINKS.CATEGORIES,
            access: [RolesEnum.admin, RolesEnum.manager, RolesEnum.editor],
        },
        {
            label: <NavLink to={RoutersEnum.sets}>Sets</NavLink>,
            key: MAIN_MENU_LINKS.SETS,
            access: [RolesEnum.admin, RolesEnum.manager, RolesEnum.editor],
        },
        {
            label: <NavLink to={RoutersEnum.translations}>Translations</NavLink>,
            key: MAIN_MENU_LINKS.TRANSLATIONS,
            access: [RolesEnum.admin, RolesEnum.manager, RolesEnum.editor],
        },
    ];

    const renderMenuItems = menuItems?.filter(
        (item) => item?.access && item?.access?.includes(me?.roleType),
    );

    return (
        <Row className={styles['header']}>
            <NavLink to="/home">
                <Logo className={styles['logo']} />
            </NavLink>
            <Menu
                mode="horizontal"
                selectedKeys={[current]}
                items={renderMenuItems}
                className={styles['main-menu']}
                theme="light"
            />

            <Dropdown menu={{ items }} trigger={['click']}>
                <UserOutlined style={{ fontSize: '18px' }} />
            </Dropdown>
        </Row>
    );
};

export default Header;
