import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.scss';
import App from './app/App';
import { ConfigProvider } from 'antd';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const customStyles = {
    colorPrimary: '#FA6A16',
    colorPrimaryHover: '#D05812',
    colorInfo: '#3498DB',
    colorInfoHover: '#3498DB',
    colorError: '#DB0000',
    colorSuccess: '#1A8A76',
};
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID;

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <QueryClientProvider client={queryClient}>
                <ConfigProvider theme={{ token: customStyles }}>
                    <App />
                </ConfigProvider>
            </QueryClientProvider>
        </GoogleOAuthProvider>
    </React.StrictMode>,
);
