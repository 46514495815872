// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Spiner_wrapper-spiner__iVqob {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/spiner/Spiner.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,WAAA;AACJ","sourcesContent":[".wrapper-spiner {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper-spiner": `Spiner_wrapper-spiner__iVqob`
};
export default ___CSS_LOADER_EXPORT___;
