import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from 'shared/assets/icons/common/large-logo-backyard.svg';
import { ReactComponent as IconGoogle } from 'shared/assets/icons/login/google.svg';
import { login } from 'shared/services/auth.service';

import { useGoogleLogin } from '@react-oauth/google';

import styles from './LoginForm.module.scss';
import { Button, message, Row } from 'antd';

type TGoogleLoginResponse = {
    access_token: string;
};

type TLoginResponse = {
    accessToken: string;
    refreshToken: string;
};

const LoginForm: FC = () => {
    const navigate = useNavigate();
    const responseGoogle = useGoogleLogin({
        onSuccess: async (codeResponse: TGoogleLoginResponse) => {
            try {
                const response: TLoginResponse = await login({
                    authToken: codeResponse?.access_token,
                });

                if (response) {
                    const { accessToken, refreshToken } = response;
                    localStorage.setItem('accessToken', accessToken);
                    localStorage.setItem('refreshToken', refreshToken);
                    navigate('/');
                } else {
                    console.error('Login response is undefined');
                }
            } catch (error) {
                if (error.response?.data?.responseStatus === 'ACCOUNT_SUSPENDED_EXCEPTION') {
                    console.error('Invalid credentials:', error.response.data.message);
                    message.error('Your account has been deactivated');
                }
                if (error.response?.data?.responseStatus === 'INVALID_CREDENTIALS_EXCEPTION') {
                    console.error('Invalid credentials:', error.response.data.message);
                    message.error('Invalid credentials. You may not have an account yet.');
                }
                console.error('Error during login:', error);
            }
        },
        onError: (e) => {
            console.error('Error during Google login:', e);
        },
        flow: 'implicit',
    });

    return (
        <Row className={styles['wrapper']}>
            <Logo className={styles['logo']} />
            <Row className={styles['container']}>
                <span className={styles['title']}>Sign In</span>
                <Button
                    onClick={() => responseGoogle()}
                    type="default"
                    className={styles['sign-in-btn']}
                >
                    <IconGoogle className={styles['icon-google']} />
                    Sign in with Google
                </Button>
            </Row>
        </Row>
    );
};
export default LoginForm;
