// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_layout__9yfk1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--color--white);
  /* Hide the scrollbar */
}

.Layout_container__RYeV5 {
  padding: 0 30px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/layout/Layout.module.scss"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;EACA,qCAAA;EACA,uBAAA;AAAJ;;AAKA;EACI,eAAA;EACA,WAAA;AAFJ","sourcesContent":[".layout {\n    \n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    background-color: var(--color--white);\n    /* Hide the scrollbar */\n   \n  \n}\n\n.container {\n    padding: 0 30px;\n    width: 100%;\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `Layout_layout__9yfk1`,
	"container": `Layout_container__RYeV5`
};
export default ___CSS_LOADER_EXPORT___;
