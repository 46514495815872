// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginForm_wrapper__4H6JD {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color--gray-5);
}
.LoginForm_wrapper__4H6JD .LoginForm_logo__efoB6 {
  margin-bottom: 66px;
}
.LoginForm_wrapper__4H6JD .LoginForm_container__RUg4r {
  height: 216px;
  width: 348px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background-color: white;
}
.LoginForm_wrapper__4H6JD .LoginForm_container__RUg4r .LoginForm_title__FOHlN {
  display: block;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 40px;
}
.LoginForm_wrapper__4H6JD .LoginForm_container__RUg4r .LoginForm_sign-in-btn__PEZyI {
  width: 250px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  border-color: #A0A0A0;
}
.LoginForm_wrapper__4H6JD .LoginForm_container__RUg4r .LoginForm_sign-in-btn__PEZyI .LoginForm_icon-google__LlLRM {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/entities_updated/user/LoginForm.module.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,sCAAA;AAAJ;AAEI;EACI,mBAAA;AAAR;AAGI;EACI,aAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;AADR;AAEQ;EACI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAAZ;AAEQ;EACI,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;AAAZ;AAEY;EACI,kBAAA;AAAhB","sourcesContent":["\n.wrapper {\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    background-color: var(--color--gray-5);\n\n    .logo {\n        margin-bottom: 66px;\n    }\n\n    .container {\n        height: 216px;\n        width: 348px;\n        display: flex ;\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n        border-radius: 14px;\n        background-color: white;\n        .title {\n            display: block;\n            font-size: 24px;\n            font-weight: 600;\n            line-height: 32px;\n            margin-bottom: 40px;\n        }\n        .sign-in-btn {\n            width: 250px;\n            height: 48px;\n            display: flex;\n            align-items: center;\n            justify-content: center;\n            border-radius: 30px;\n            border-color: #A0A0A0;\n\n            .icon-google {\n                margin-right: 10px;\n            }\n        }\n    }\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `LoginForm_wrapper__4H6JD`,
	"logo": `LoginForm_logo__efoB6`,
	"container": `LoginForm_container__RUg4r`,
	"title": `LoginForm_title__FOHlN`,
	"sign-in-btn": `LoginForm_sign-in-btn__PEZyI`,
	"icon-google": `LoginForm_icon-google__LlLRM`
};
export default ___CSS_LOADER_EXPORT___;
